<script>
  import { onMount } from 'svelte';
  import Header from './components/header/Header.svelte'
  import TitleSection from './components/sections/DealsSection.svelte'
  import CtaSection from './components/sections/BonusSection.svelte'
  import TrustSection from './components/sections/TrustSection.svelte'
  import Footer from './components/footer/Footer.svelte'
  import QuickLinksSection from './components/sections/QuickLinksSection.svelte'  
  export let data = [];
  let totalItems,HeadlineSummary,HeadlineTitle;
  let fetchDataType = "gadgets";
  // let NewArrivals2 = false;
  $: passedNewArrivals = false;


  
  onMount(async () => {
    const urlParams = new URL(window.location.href);
    const userParam = urlParams.searchParams.get('u');
    const user = userParam ? userParam.split('&')[0].trim() : null;   
  

    console.log("User: ", user);
    if (user === "gizmos") {
      // console.log("gizmos");
      fetchDataType = "gizmos";
    }
    else if (user === "gadgets") {
      // console.log("gadgets");
      fetchDataType = "gadgets";
    }
    else if(!user) {
      console.log("No user found in URL, fetching backup data...");
      fetchDataType = "gizmos";

    }
    // Fetch data based on fetchDataType
    if (fetchDataType) {
      data = await fetchData(fetchDataType);
      console.log("Fetched Data: ", fetchDataType, data);
      totalItems = data.length > 0 ? data.findIndex(item => item.offerName === "NewArrivals") || data.length : 0;
      HeadlineSummary = data.length > 0 ? data.findIndex(item => item.offerName === "HeadlineSummary") || data.length : 0;
      HeadlineTitle = data.length > 0 ? data.findIndex(item => item.offerName === "HeadlineTitle") || data.length : 0;
    }
  });

  
  $:SectionOne = data.slice(0,HeadlineSummary);
  $:QuickLinkItems = data.slice(HeadlineSummary, HeadlineTitle);


  async function fetchData(dataType) {
    try {
      // Fetch data locally
      // const response = await fetch('http://localhost:52354', {
      
      const response = await fetch('https://ecom-worker.oleg-c50.workers.dev/', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: dataType 
        })
      });

      const result = await response.json();
    
      // console.log("Result: ", result); // Log result
      if (result.data) {
        console.log("Data found: ", result.data);
        return result.data;
      } else {
        console.error('No data found');
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  }
</script>

<main>
  <div class="container">
    <Header totalItems={totalItems} />
    <TitleSection />
    {#each SectionOne as item, index}
        <CtaSection
          OfferLenght={totalItems}
          offerNumber={totalItems - index}
          offerName={item.offerName}
          offerLink={item.offerLink}
          offerTitle={item.offerTitle}
          offerReview={item.offerReview}
          offerStar={item.offerStar}
          offerImage={item.offerImage}
          offerSummary={item.offerSummary}
        />
    {/each}

    Quick Link
    {#each SectionOne as item, index}
    {#if item.offerName === "NewArrivals"}

    {:else}
      <QuickLinksSection
        OfferLenght={totalItems}
        offerNumber={index+1}
        offerName={item.offerName}
        offerLink={item.offerLink}
        offerTitle={item.offerTitle}
        offerReview={item.offerReview}
        offerStar={item.offerStar}
        offerImage={item.offerImage}
        offerSummary={item.offerSummary}
      />
    {/if}
  {/each}
  
    <TrustSection />
    <Footer />
  </div>
</main>

<style>



  /* Center the main content both horizontally and vertically */
  main {
    /* width: 100%; */
    min-height: 100vh; /* Makes sure it takes the full viewport height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden; /* Prevents horizontal scrolling */

  }

  /* Container width adjustments for different screen sizes */
  .container {
    background-color: rgb(255, 255, 255);

    width: 100%;
    max-width: 920px; /* Set max-width so it doesn't exceed this value */
    margin: 0 auto; /* Center horizontally */
    padding: 0 1rem; /* Add some padding for smaller screens */
  }

  /* Adjust the max-width of the container at different breakpoints */
  @media (max-width: 1200px) {
    .container {
      max-width: 770px;
    }
  }

  @media (max-width: 1000px) {
    .container {
      max-width: 570px;
    }
  }

  @media (max-width: 770px) {
    .container {
      max-width: 510px;
    }
  }

  @media (max-width: 570px) {
    .container {
      max-width: 380px;
    }
  }
  @media (max-width: 370px) {
    .container {
      max-width: 100%;
    }
  }
  /* @media (max-width: 470px) {
    .container {
      max-width: 100%;
    }
  }
  @media (max-width: 470px) {
    .container {
      max-width: 100%;
    }
  } */
</style>